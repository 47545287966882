import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { first, Observable, switchMap } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AddAuthHeaderInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const authHeader = request.headers.get('Authorization');

    if (!authHeader) {
      const userService = this.injector.get(UserService);

      return userService.idToken$.pipe(
        first(),
        switchMap(idToken => {
          if (idToken) {
            return next.handle(request.clone({
              headers: request.headers.set('Authorization', idToken),
            }));
          }
          return next.handle(request);
        })
      )
    }

    return next.handle(request);
  }
}
