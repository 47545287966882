import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorage {
  private localStorageSupported = (() => {
    if (typeof localStorage === 'undefined') {
      return false;
    }
    if (!localStorage) {
      return false;
    }

    if (!localStorage.setItem) {
      return false;
    }

    if(typeof localStorage.setItem !== 'function') {
      return false;
    }

    const storageKey = '__test__';
    localStorage.setItem(storageKey, storageKey);
    if (localStorage.getItem(storageKey) !== storageKey) {
      localStorage.removeItem(storageKey);
      return false;
    }
    localStorage.removeItem(storageKey);

    return true;
  })();

  private data : {
    [key: string]: string
  } = {};

  setItem(key: string, value: string) {
    if (this.localStorageSupported) {
      localStorage.setItem(key, value);
    }
    else {
      this.data[key] = value;
    }
  }

  getItem(key: string) {
    if (this.localStorageSupported) {
      return localStorage.getItem(key);
    }
    return this.data[key];
  }

  removeItem(key: string) {
    if (this.localStorageSupported) {
      localStorage.removeItem(key);
    }
    else {
      delete this.data[key];
    }
  }
}
