import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SEOService } from '@sitemule/utils/services/seo';
import { first } from 'rxjs';
import { SiteSettingsService } from './services/siteSettings.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private siteSettingsService: SiteSettingsService,
    private translate: TranslateService,
    private seoService: SEOService,
  ) {
    this.siteSettingsService.locale$.subscribe(locale => {
      this.translate.use(locale);
    })
  }

  loadTranslation() {
    return new Promise<void>((res) => {
      const locale = this.siteSettingsService.locale$.getValue();
      const availableLanguages = this.siteSettingsService.availableLanguages$.getValue();

      this.translate.addLangs(availableLanguages);
      this.translate.setDefaultLang(locale);
      this.seoService.setPageLanguage(locale);

      this.translate
        .use(locale)
        .pipe(first())
        .subscribe(() => res());
    });
  }

  downloadInitialData() {
    return Promise.all([this.loadTranslation()]);
  }
}
