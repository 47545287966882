import { inject, NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { map } from 'rxjs';
import { UserService } from './services/user.service';

const redirectToLoginPageIfNotLoggedIn = () => {
  const router = inject(Router);
  return inject(UserService).isLoggedIn$.pipe(
    map(isLoggedIn => {
      if (!isLoggedIn) {
        router.navigateByUrl('/login');
      }
      return isLoggedIn;
    })
  );
};
const redirectToDashboardPageIfLoggedIn = () => {
  const router = inject(Router);
  return inject(UserService).isLoggedIn$.pipe(
    map(isLoggedIn => {
      if (isLoggedIn) {
        router.navigateByUrl('/');
      }
      return !isLoggedIn;
    })
  );
};

const routes : Routes = [
  {
    path: '',
    canActivate: [
      redirectToLoginPageIfNotLoggedIn
    ],
    loadChildren: () =>
      import('./views/websites/websites.module').then(
        (mod) => mod.WebsitesModule
      ),
  },
  {
    path: 'login',
    canActivate: [
      redirectToDashboardPageIfLoggedIn
    ],
    loadChildren: () =>
      import('./views/homepage/homepage.module').then(
        (mod) => mod.HomepageModule
      ),
  },
  {
    path: 'register',
    canActivate: [
      redirectToDashboardPageIfLoggedIn
    ],
    loadChildren: () =>
      import('./views/register-new-account/register-new-account.module').then(
        (mod) => mod.RegisterNewAccountModule
      ),
  },
  {
    path: 'forgot-password',
    canActivate: [
      redirectToDashboardPageIfLoggedIn
    ],
    loadChildren: () =>
      import('./views/forgot-password/forgot-password.module').then(
        (mod) => mod.ForgotPasswordModule
      ),
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./views/logout/logout.module').then(
        (mod) => mod.LogoutModule
      ),
  },
  {
    path: 'websites',
    canActivate: [
      redirectToLoginPageIfNotLoggedIn
    ],
    loadChildren: () =>
      import('./views/websites/websites.module').then(
        (mod) => mod.WebsitesModule
      ),
  },
  {
    path: 'app/:id',
    canActivate: [
      redirectToLoginPageIfNotLoggedIn
    ],
    loadChildren: () =>
      import('./views/advanced-input/advanced-input.module').then(
        (mod) => mod.AdvancedInputModule
      ),
  },
  {
    path: 'prompt',
    canActivate: [
      redirectToLoginPageIfNotLoggedIn
    ],
    loadChildren: () =>
      import('./views/prompt/prompt.module').then(
        (mod) => mod.PromptModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./views/not-found-page/not-found-page.module').then(
        (mod) => mod.NotFoundpageModule
      ),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    anchorScrolling: "enabled",
    scrollPositionRestoration: 'top',
    enableTracing: false
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
