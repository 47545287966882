import { Injectable } from '@angular/core';
import { Auth, GoogleAuthProvider, authState, createUserWithEmailAndPassword, idToken, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signOut, updateProfile, user } from '@angular/fire/auth';
import { Observable, from, map, switchMap } from 'rxjs';
const googleProvider = new GoogleAuthProvider();
@Injectable({
  providedIn: 'root',
})
export class UserService {
  user$ = user(this.auth);
  isLoggedIn$ = authState(this.auth).pipe(map((state) => !!state));
  idToken$ = idToken(this.auth);

  constructor(
    private auth: Auth,
  ) {}

  login(email: string, password: string) {
    return from(
      signInWithEmailAndPassword(this.auth, email, password)
    );
  }

  sendPasswordResetEmail(email: string) {
    return from(
      sendPasswordResetEmail(this.auth, email)
    );
  }

  logout() {
    return from(
      signOut(this.auth)
    );
  }

  register({
    email,
    password,
    name,
  }: {
    email: string;
    name?: string;
    password: string;
  }) : Observable<void> {
    return from(createUserWithEmailAndPassword(this.auth, email, password)).pipe(
      switchMap((user) => {
        return from(updateProfile(user.user, {
          displayName: name || email
        }))
      })
    );
  }

  loginWithGoogle() {
    signInWithPopup(this.auth, googleProvider)
  }
}
