<router-outlet></router-outlet>
<sm-toast></sm-toast>
<div [style]="{ position: 'fixed', bottom: '30px', right: '30px' }">
  <p-dropdown
    [options]="selectableLanguages"
    [ngModel]="selectedLanguage"
    optionLabel="label"
    optionValue="key"
    *ngIf="selectedLanguage$ | async as selectedLanguage"
    (onChange)="languageChange($event)" />
</div>
