import { Injectable, inject } from '@angular/core';
import { SiteSettingsService as AbstractSiteSettingsService } from '@sitemule/core/abstract-services/site-settings.service';
import { BehaviorSubject } from 'rxjs';
import { LocalStorage } from './localStorage.service';

const availableLanguages = ['en', 'da'];
const localStorageKey = 'selected-language';
@Injectable({
  providedIn: 'root',
})
export class SiteSettingsService extends AbstractSiteSettingsService {
  private readonly localStorage = inject(LocalStorage);

  override currency$ = new BehaviorSubject('DKK');
  override locale$ = new BehaviorSubject(this.localStorage.getItem(localStorageKey) || availableLanguages[0]);
  availableLanguages$ = new BehaviorSubject(availableLanguages);

  override siteName$ = new BehaviorSubject('Sitemule Websites');

  public changeLanguage(lang: string) {
    this.localStorage.setItem(localStorageKey, lang);
    this.locale$.next(lang);
  }
}
