export const environment = {
    sentryDSN: 'https://caa3415a67594f99aa618af4aedc4a71@o263138.ingest.sentry.io/4505252384669696',
    siteBuilderHost: 'https://site-builder-api-ivms6ebhxa-lz.a.run.app',
    mediaManagerHost: 'https://media-manager-api-ivms6ebhxa-lz.a.run.app',
    contentGeneratorHost: 'https://content-generator-api-ivms6ebhxa-lz.a.run.app',
    firebaseConfig: {
      apiKey: 'AIzaSyB0MxXXR1JpLhYVFqs0C4tnr1WSKvAmVPA',
      authDomain: 'ai-site-generator-3bb5f.firebaseapp.com',
      projectId: 'ai-site-generator-3bb5f',
      storageBucket: 'ai-site-generator-3bb5f.appspot.com',
      messagingSenderId: '182336605390',
      appId: '1:182336605390:web:d652facacfa2f1ff34549f',
    },
    design: [
      {
        name: 'Copenhagen',
        link: 'https://site-fc3d379c-dea7-4ece-b27f-95b711333556-ivms6ebhxa-lz.a.run.app',
        image: '/assets/images/design-copenhagen.png',
      },
      {
        name: 'Bremen',
        link: 'https://site-20fe1c8e-188c-4018-b727-7fd1b1a1966d-ivms6ebhxa-lz.a.run.app',
        image: '/assets/images/design-bremen.png',
      },
      {
        name: 'BremenLight',
        link: 'https://site-20fe1c8e-188c-4018-b727-7fd1b1a1966d-ivms6ebhxa-lz.a.run.app',
        image: '/assets/images/design-bremen-light.png',
      },
      {
        name: 'London',
        link: 'https://site-20fe1c8e-188c-4018-b727-7fd1b1a1966d-ivms6ebhxa-lz.a.run.app',
        image: '/assets/images/design-london.png',
      },
      {
        name: 'LondonLight',
        link: 'https://site-20fe1c8e-188c-4018-b727-7fd1b1a1966d-ivms6ebhxa-lz.a.run.app',
        image: '/assets/images/design-london.png',
      },
      {
        name: 'Kathmandu',
        link: 'https://site-20fe1c8e-188c-4018-b727-7fd1b1a1966d-ivms6ebhxa-lz.a.run.app',
        image: '/assets/images/design-kathmandu.png',
      },
      {
        name: 'KathmanduLight',
        link: 'https://site-20fe1c8e-188c-4018-b727-7fd1b1a1966d-ivms6ebhxa-lz.a.run.app',
        image: '/assets/images/design-kathmandu-light.png',
      },
      {
        name: 'KathmanduDark',
        link: 'https://site-20fe1c8e-188c-4018-b727-7fd1b1a1966d-ivms6ebhxa-lz.a.run.app',
        image: '/assets/images/design-kathmandu-dark.png',
      },
      {
        name: 'Davao',
        link: 'https://site-20fe1c8e-188c-4018-b727-7fd1b1a1966d-ivms6ebhxa-lz.a.run.app',
        image: '/assets/images/design-davao.png',
      },
    ]
  };
