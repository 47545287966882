import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { SiteSettingsService } from './services/siteSettings.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private readonly siteSettings = inject(SiteSettingsService);

  constructor(private router: Router, private userService: UserService) {
    // This logic is to handle when user open's multiple tabs and signout/signin on one of the tab
    //   We should signout/signin in all tabs
    this.userService.isLoggedIn$.subscribe((isLoggedIn) => {
      // redirect to login page if user session expires
      if (!isLoggedIn && (location.pathname === '/' || location.pathname.startsWith('/app/'))) {
        this.router.navigateByUrl('/login');
        return;
      }

      // redirect to dashboard if user session is restored
      if (isLoggedIn && (location.pathname !== '/' && !location.pathname.startsWith('/app/'))) {
        this.router.navigateByUrl('/');
        return;
      }
    });
  }

  public readonly selectableLanguages = [
    {
      label: 'English',
      key: 'en'
    },
    {
      label: 'Dansk',
      key: 'da'
    },
  ];

  public readonly selectedLanguage$ = this.siteSettings.locale$;

  public languageChange(evt: { value: string }) {
    this.siteSettings.changeLanguage(evt.value);
  }
}
