import { CurrencyPipe, registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import da from '@angular/common/locales/da';
import en from '@angular/common/locales/en';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SITE_SETTINGS_SERVICE_TOKEN } from '@sitemule/core/abstract-services/site-settings.service';
import { CustomCurrencyPipe } from '@sitemule/core/pipes/custom-currency';
import { ToastModule, ToastService } from '@sitemule/ng-components/components/toast';
import { AddAcceptLanguageHttpInterceptor } from '@sitemule/ng-components/http-interceptors/add-accept-language';
import { HttpErrorInterceptor as BaseHttpErrorInterceptor } from '@sitemule/ng-components/http-interceptors/log-error';
import { RetryHttpInterceptor } from '@sitemule/ng-components/http-interceptors/retry-http';
import { DropdownModule } from 'primeng/dropdown';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { AppErrorHandler } from './error-handlers/app.error-handler';
import { AddAuthHeaderInterceptor } from './interceptors/add-auth-header.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-interceptor.interceptor';
import { SiteSettingsService } from './services/siteSettings.service';


registerLocaleData(en);
registerLocaleData(da);


const translateLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, '/assets/i18n/', '.json');

export class MissingTranslationHelper implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.log(`Missing translation for: ${params.key}`);
    return params.key;
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => initializeFirestore(getApp(), { ignoreUndefinedProperties: true })),
    provideStorage(() => getStorage()),
    provideAuth(() => getAuth()),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationHelper,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastModule,
    DropdownModule,
    FormsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appService: AppService) => () =>
        appService.downloadInitialData(),
      deps: [AppService],
      multi: true,
    },
    {
      provide: SITE_SETTINGS_SERVICE_TOKEN,
      useClass: SiteSettingsService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAcceptLanguageHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseHttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAuthHeaderInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    CurrencyPipe,
    CustomCurrencyPipe,
    ToastService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
