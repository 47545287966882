import {
  HttpContextToken, HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ToastService } from '@sitemule/ng-components/components/toast';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const PERMANENT_TOAST_IGNORE_STATUS = [401, 406]; // ['Unauthorized', 'Missing CPR']
export const IGNORE_TOAST_ON_STATUS = new HttpContextToken<number[]>(() => []);

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const modifiedReq = request;

    return next
      .handle(modifiedReq)
      .pipe(
        catchError((error) => {
          const toast = this.injector.get(ToastService);
          const ignoreToast = [...PERMANENT_TOAST_IGNORE_STATUS, ...modifiedReq.context.get(IGNORE_TOAST_ON_STATUS)].indexOf(error.status) > -1;

          if (!ignoreToast && toast) {
            toast.push({
              type: 'warning',
              title: error.statusText,
              message: error.error?.message || error.message,
              autoHide: true
            });
          }

          return throwError(() => error);
        })
      );
  }
}
