import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.sentryDSN) {
  Sentry.init({
    dsn: environment.sentryDSN,
    integrations: [],
    tracesSampleRate: 0,
    autoSessionTracking: false,
    sendClientReports: false,
  });
}

// This console is for Sentry, so we know where user landed.
console.log(`Landed at ${location.toString()}`);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
